//Polyfill
import "core-js";

//Can't resolve 'regenerator-runtime/runtime'が表示されたとき(async awaitを使う場合など)
//import  regeneratorRuntime  from  "regenerator-runtime" ;

import jQuery from "jquery";

const $ = jQuery; // ドルマークに参照を代入(慣習的な $ を使うため)
//背景の動画がスクロールすると不透明度が上がる
$(document).ready(function () {
  const scrollAmount = 0;
  const maxScroll = 2000;

  $(window).scroll(function () {
    const currentScroll = $(this).scrollTop();

    // スクロールが下方向に進む場合
    if (currentScroll > scrollAmount) {
      // opacityを段階的に減少させる
      const opacity = 1 - (currentScroll / maxScroll);
      opacity = Math.max(0.1, opacity); // 最低値0.1を設定
      $(".front-mv-obj").css("opacity", opacity);
    }
    // スクロールが上方向に戻る場合
    else {
      // スクロールの量を更新して、opacityを再計算
      scrollAmount = currentScroll;
      const opacity = 1 - (scrollAmount / maxScroll);
      opacity = Math.max(0.1, opacity); // 最低値0.1を設定
      $(".front-mv-obj").css("opacity", opacity);
    }
  });
});

// 最初の開く挙動
$(document).ready(function() {
  // 最初の幕開けの挙動
  if (!localStorage.getItem('visitedBefore')) {
    // 1回目の訪問時の処理

    setTimeout(function() {
      $(".front-fv--bg").addClass("open");
      setTimeout(function() {
        $(".front-fv--logo").addClass("open");
        setTimeout(function() {
          $(".front-fv").addClass("open");
        }, 3000);
      }, 1000);
    }, 1000);

    setTimeout(function() {
      $(".front-top-main--title,.front-top-main--text").addClass("open");
    }, 5500);
    setTimeout(function() {
      $(".front-top-main--sub").addClass("open");
    }, 6000);

    // localStorageにキーをセット
    localStorage.setItem('visitedBefore', 'true');
  } else {
    $(".front-fv").remove();
    $(".front-top-main--title,.front-top-main--text").addClass("indicate");
  }

  //スクロールしたらbgのスタイルを追加
  $(window).scroll(function() {
    var scrollPos = $(window).scrollTop();
    if (scrollPos > 50) { // 50pxスクロールされたら
      $(".header").addClass("bg");
    } else {
      $(".header").removeClass("bg");
    }
  });

  //ナビのボタンの挙動
  $(".nav-btn").on("click", function() {
    $(this).toggleClass("open");
    $(".nav-gr").toggleClass("open");
  });
  $(".nav-gr a").on("click", function() {
    $(".nav-gr,.nav-btn").removeClass("open");
  });

  //ロゴの回転
  const $logoContainer = $(".front-link-logo--wrap");
  const logoWidth = $logoContainer.width();

  // コンテナの内容を複製して並べる
  $logoContainer.append($logoContainer.html());

  // アニメーション関数
  function scrollLogos() {
    $logoContainer.animate(
      { left: -logoWidth },
      5000, // アニメーション時間
      "linear",
      function() {
        // アニメーションが終わったら、位置をリセット
        $logoContainer.css({ left: "0px" });

        // アニメーションを再開
        scrollLogos();
      }
    );
  }

  // アニメーションを開始
  scrollLogos();
});

//画面の表示領域にはいったら表示させる
$(window).on("scroll", function() {
  $(".box_fade").each(function() {
    var position = $(this).offset().top;  // 要素のページ上部からの位置
    var scroll = $(window).scrollTop();   // スクロール位置
    var windowHeight = $(window).height();  // ウィンドウの高さ

    if (scroll > position - windowHeight + 100 + 100) {
      $(this).css("opacity", "1");
      $(this).css("transform", "translateY(0)");
    }
  });
});


//TOPスライド
const swiper = new Swiper(".biz-slide", {
  effect: "fade",
  speed: 3000,
  fadeEffect: {
    crossFade: true
  },
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  pagination: {
    el: ".front-biz-slide .swiper-pagination",
    clickable: true,
  },
});

//TOPのロゴの回転
const swiper = new Swiper(".swiper-logo", {
  loop: true, // ループ有効
  slidesPerView: 2, // 一度に表示する枚数
  spaceBetween:20,
  speed: 5000, // ループの時間
  allowTouchMove: false, // スワイプ無効
  autoplay: {
    delay: 0, // 途切れなくループ
  },
  breakpoints: {
    // スライドの表示枚数：768px以上の場合
    768: {
      slidesPerView: 5,
      spaceBetween:40,
    }
  }
});